{
  "asyncapi": "3.0.0",
  "info": {
    "title": "Springwolf example project - Kafka",
    "version": "1.0.0",
    "description": "Springwolf example project to demonstrate springwolfs abilities, including **markdown** support for descriptions.",
    "contact": {
      "name": "springwolf",
      "url": "https://github.com/springwolf/springwolf-core",
      "email": "example@example.com"
    },
    "license": {
      "name": "Apache License 2.0"
    },
    "x-generator": "springwolf"
  },
  "defaultContentType": "application/json",
  "servers": {
    "kafka-server": {
      "host": "kafka:29092",
      "protocol": "kafka"
    }
  },
  "channels": {
    "another-topic": {
      "address": "another-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "avro-topic": {
      "address": "avro-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
        }
      }
    },
    "example-topic": {
      "address": "example-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "integer-topic": {
      "address": "integer-topic",
      "messages": {
        "java.lang.Integer": {
          "$ref": "#/components/messages/java.lang.Integer"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "multi-payload-topic": {
      "address": "multi-payload-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
        },
        "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
        },
        "javax.money.MonetaryAmount": {
          "$ref": "#/components/messages/javax.money.MonetaryAmount"
        }
      }
    },
    "no-payload-used-topic": {
      "address": "no-payload-used-topic",
      "messages": {
        "PayloadNotUsed": {
          "$ref": "#/components/messages/PayloadNotUsed"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "protobuf-topic": {
      "address": "protobuf-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "string-topic": {
      "address": "string-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope"
        },
        "java.lang.String": {
          "$ref": "#/components/messages/java.lang.String"
        }
      }
    },
    "topic-defined-via-asyncPublisher-annotation": {
      "address": "topic-defined-via-asyncPublisher-annotation",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
        }
      },
      "servers": [
        {
          "$ref": "#/servers/kafka-server"
        }
      ]
    },
    "vehicle-topic": {
      "address": "vehicle-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
        }
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      }
    },
    "xml-topic": {
      "address": "xml-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
        }
      }
    },
    "yaml-topic": {
      "address": "yaml-topic",
      "messages": {
        "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto": {
          "$ref": "#/components/messages/io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
        }
      }
    }
  },
  "components": {
    "schemas": {
      "HeadersNotDocumented": {
        "title": "HeadersNotDocumented",
        "type": "object",
        "properties": { },
        "description": "There can be headers, but they are not explicitly documented.",
        "examples": [
          { }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "There can be headers, but they are not explicitly documented.",
          "title": "HeadersNotDocumented",
          "type": "object"
        }
      },
      "HeadersNotUsed": {
        "title": "HeadersNotUsed",
        "type": "object",
        "properties": { },
        "description": "No headers are present.",
        "examples": [
          { }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "No headers are present.",
          "title": "HeadersNotUsed",
          "type": "object"
        }
      },
      "PayloadNotUsed": {
        "title": "PayloadNotUsed",
        "type": "object",
        "properties": { },
        "description": "No payload specified",
        "examples": [
          { }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "No payload specified",
          "title": "PayloadNotUsed",
          "type": "object"
        }
      },
      "SpringDefaultHeaderAndCloudEvent": {
        "title": "SpringDefaultHeaderAndCloudEvent",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
            ]
          },
          "ce_id": {
            "type": "string",
            "description": "CloudEvent Id Header",
            "enum": [
              "2c60089e-6f39-459d-8ced-2d6df7e4c03a"
            ],
            "examples": [
              "2c60089e-6f39-459d-8ced-2d6df7e4c03a"
            ]
          },
          "ce_source": {
            "type": "string",
            "description": "CloudEvent Source Header",
            "enum": [
              "http://localhost"
            ],
            "examples": [
              "http://localhost"
            ]
          },
          "ce_specversion": {
            "type": "string",
            "description": "CloudEvent Spec Version Header",
            "enum": [
              "1.0"
            ],
            "examples": [
              "1.0"
            ]
          },
          "ce_subject": {
            "type": "string",
            "description": "CloudEvent Subject Header",
            "enum": [
              "Springwolf example project - Kafka"
            ],
            "examples": [
              "Springwolf example project - Kafka"
            ]
          },
          "ce_time": {
            "type": "string",
            "description": "CloudEvent Time Header",
            "enum": [
              "2023-10-28 20:01:23+00:00"
            ],
            "examples": [
              "2023-10-28 20:01:23+00:00"
            ]
          },
          "ce_type": {
            "type": "string",
            "description": "CloudEvent Payload Type Header",
            "enum": [
              "NestedPayloadDto.v1"
            ],
            "examples": [
              "NestedPayloadDto.v1"
            ]
          },
          "content-type": {
            "type": "string",
            "description": "CloudEvent Content-Type Header",
            "enum": [
              "application/json"
            ],
            "examples": [
              "application/json"
            ]
          }
        },
        "description": "Spring __TypeId__ and CloudEvent Headers",
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto",
            "ce_id": "2c60089e-6f39-459d-8ced-2d6df7e4c03a",
            "ce_source": "http://localhost",
            "ce_specversion": "1.0",
            "ce_subject": "Springwolf example project - Kafka",
            "ce_time": "2023-10-28 20:01:23+00:00",
            "ce_type": "NestedPayloadDto.v1",
            "content-type": "application/json"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Spring __TypeId__ and CloudEvent Headers",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
              ],
              "type": "string"
            },
            "ce_id": {
              "description": "CloudEvent Id Header",
              "enum": [
                "2c60089e-6f39-459d-8ced-2d6df7e4c03a"
              ],
              "type": "string"
            },
            "ce_source": {
              "description": "CloudEvent Source Header",
              "enum": [
                "http://localhost"
              ],
              "type": "string"
            },
            "ce_specversion": {
              "description": "CloudEvent Spec Version Header",
              "enum": [
                "1.0"
              ],
              "type": "string"
            },
            "ce_subject": {
              "description": "CloudEvent Subject Header",
              "enum": [
                "Springwolf example project - Kafka"
              ],
              "type": "string"
            },
            "ce_time": {
              "description": "CloudEvent Time Header",
              "enum": [
                "2023-10-28 20:01:23+00:00"
              ],
              "type": "string"
            },
            "ce_type": {
              "description": "CloudEvent Payload Type Header",
              "enum": [
                "NestedPayloadDto.v1"
              ],
              "type": "string"
            },
            "content-type": {
              "description": "CloudEvent Content-Type Header",
              "enum": [
                "application/json"
              ],
              "type": "string"
            }
          },
          "title": "SpringDefaultHeaderAndCloudEvent",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-AnotherPayloadAvroDto": {
        "title": "SpringKafkaDefaultHeaders-AnotherPayloadAvroDto",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-AnotherPayloadAvroDto",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-AnotherPayloadDto": {
        "title": "SpringKafkaDefaultHeaders-AnotherPayloadDto",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-AnotherPayloadDto",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-ExamplePayloadDto-220435997": {
        "title": "SpringKafkaDefaultHeaders-ExamplePayloadDto-220435997",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
            ]
          },
          "kafka_offset": {
            "type": "number",
            "examples": [
              1.1
            ]
          },
          "kafka_receivedMessageKey": {
            "type": "string",
            "examples": [
              "\"string\""
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto",
            "kafka_offset": 1.1,
            "kafka_receivedMessageKey": "string"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
              ],
              "type": "string"
            },
            "kafka_offset": {
              "type": "number"
            },
            "kafka_receivedMessageKey": {
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-ExamplePayloadDto-220435997",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-Message": {
        "title": "SpringKafkaDefaultHeaders-Message",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-Message",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-MonetaryAmount": {
        "title": "SpringKafkaDefaultHeaders-MonetaryAmount",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "javax.money.MonetaryAmount"
            ],
            "examples": [
              "javax.money.MonetaryAmount"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "javax.money.MonetaryAmount"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "javax.money.MonetaryAmount"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-MonetaryAmount",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-PayloadNotUsed": {
        "title": "SpringKafkaDefaultHeaders-PayloadNotUsed",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "PayloadNotUsed"
            ],
            "examples": [
              "PayloadNotUsed"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "PayloadNotUsed"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "PayloadNotUsed"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-PayloadNotUsed",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-VehicleBase": {
        "title": "SpringKafkaDefaultHeaders-VehicleBase",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-VehicleBase",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-XmlPayloadDto": {
        "title": "SpringKafkaDefaultHeaders-XmlPayloadDto",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-XmlPayloadDto",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-YamlPayloadDto": {
        "title": "SpringKafkaDefaultHeaders-YamlPayloadDto",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
            ],
            "examples": [
              "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-YamlPayloadDto",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-integer": {
        "title": "SpringKafkaDefaultHeaders-integer",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "java.lang.Integer"
            ],
            "examples": [
              "java.lang.Integer"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "java.lang.Integer"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "java.lang.Integer"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-integer",
          "type": "object"
        }
      },
      "SpringKafkaDefaultHeaders-string": {
        "title": "SpringKafkaDefaultHeaders-string",
        "type": "object",
        "properties": {
          "__TypeId__": {
            "type": "string",
            "description": "Spring Type Id Header",
            "enum": [
              "java.lang.String"
            ],
            "examples": [
              "java.lang.String"
            ]
          }
        },
        "examples": [
          {
            "__TypeId__": "java.lang.String"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "__TypeId__": {
              "description": "Spring Type Id Header",
              "enum": [
                "java.lang.String"
              ],
              "type": "string"
            }
          },
          "title": "SpringKafkaDefaultHeaders-string",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope": {
        "type": "string",
        "description": "Payload description using @Schema annotation and @AsyncApiPayload within envelope class",
        "maxLength": 100,
        "examples": [
          "\"string\""
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Payload description using @Schema annotation and @AsyncApiPayload within envelope class",
          "maxLength": 100,
          "type": "string"
        }
      },
      "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto": {
        "title": "AnotherPayloadAvroDto",
        "type": "object",
        "properties": {
          "examplePayloadAvroDto": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dto.avro.ExamplePayloadAvroDto"
          },
          "someEnum": {
            "type": "string",
            "enum": [
              "FOO1",
              "FOO2",
              "FOO3"
            ]
          }
        },
        "examples": [
          {
            "examplePayloadAvroDto": {
              "someLong": 0,
              "someString": "string"
            },
            "someEnum": "FOO1"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "examplePayloadAvroDto": {
              "properties": {
                "someLong": {
                  "format": "int64",
                  "type": "integer"
                },
                "someString": {
                  "type": "string"
                }
              },
              "title": "ExamplePayloadAvroDto",
              "type": "object"
            },
            "someEnum": {
              "enum": [
                "FOO1",
                "FOO2",
                "FOO3"
              ],
              "type": "string"
            }
          },
          "title": "AnotherPayloadAvroDto",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dto.avro.ExamplePayloadAvroDto": {
        "title": "ExamplePayloadAvroDto",
        "type": "object",
        "properties": {
          "someLong": {
            "type": "integer",
            "format": "int64"
          },
          "someString": {
            "type": "string"
          }
        },
        "examples": [
          {
            "someLong": 0,
            "someString": "string"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "someLong": {
              "format": "int64",
              "type": "integer"
            },
            "someString": {
              "type": "string"
            }
          },
          "title": "ExamplePayloadAvroDto",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message": {
        "title": "Message",
        "type": "object",
        "properties": {
          "someEnum": {
            "type": "string",
            "enum": [
              "FOO1",
              "FOO2",
              "FOO3",
              "UNRECOGNIZED"
            ]
          },
          "someLong": {
            "type": "integer",
            "format": "int64"
          },
          "someString": {
            "type": "string"
          }
        },
        "examples": [
          {
            "someEnum": "FOO1",
            "someLong": 0,
            "someString": "string"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "someEnum": {
              "enum": [
                "FOO1",
                "FOO2",
                "FOO3",
                "UNRECOGNIZED"
              ],
              "type": "string"
            },
            "someLong": {
              "format": "int64",
              "type": "integer"
            },
            "someString": {
              "type": "string"
            }
          },
          "title": "Message",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto": {
        "title": "AnotherPayloadDto",
        "type": "object",
        "properties": {
          "example": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
          },
          "foo": {
            "type": "string",
            "description": "Foo field",
            "maxLength": 100,
            "examples": [
              "bar"
            ]
          }
        },
        "description": "Another payload model",
        "examples": [
          {
            "example": {
              "someEnum": "FOO2",
              "someLong": 5,
              "someString": "some string value"
            },
            "foo": "bar"
          }
        ],
        "required": [
          "example"
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Another payload model",
          "properties": {
            "example": {
              "description": "Example payload model demonstrating markdown text styling:\n**bold**, *cursive* and <u>underlined</u>\n",
              "properties": {
                "someEnum": {
                  "description": "Some enum field",
                  "enum": [
                    "FOO1",
                    "FOO2",
                    "FOO3"
                  ],
                  "type": "string"
                },
                "someLong": {
                  "description": "Some long field",
                  "format": "int64",
                  "minimum": 0,
                  "type": "integer"
                },
                "someString": {
                  "description": "###  Some string field with Markdown\n\n- **bold**\n- *cursive*\n- images: <img src=\"./assets/springwolf-logo.png\" alt=\"Springwolf\" height=\"50\"/>\n- and code blocks (json, http, java)\n  ```json\n  {\n    \"key1\":\"value1\",\n    \"key2\":\"value2\"\n  }\n  ```\n",
                  "type": "string"
                }
              },
              "required": [
                "someEnum",
                "someString"
              ],
              "title": "ExamplePayloadDto",
              "type": "object"
            },
            "foo": {
              "description": "Foo field",
              "maxLength": 100,
              "type": "string"
            }
          },
          "required": [
            "example"
          ],
          "title": "AnotherPayloadDto",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto": {
        "title": "ExamplePayloadDto",
        "type": "object",
        "properties": {
          "someEnum": {
            "type": "string",
            "description": "Some enum field",
            "enum": [
              "FOO1",
              "FOO2",
              "FOO3"
            ],
            "examples": [
              "FOO2"
            ]
          },
          "someLong": {
            "type": "integer",
            "description": "Some long field",
            "format": "int64",
            "minimum": 0,
            "examples": [
              5
            ]
          },
          "someString": {
            "type": "string",
            "description": "###  Some string field with Markdown\n\n- **bold**\n- *cursive*\n- images: <img src=\"./assets/springwolf-logo.png\" alt=\"Springwolf\" height=\"50\"/>\n- and code blocks (json, http, java)\n  ```json\n  {\n    \"key1\":\"value1\",\n    \"key2\":\"value2\"\n  }\n  ```\n",
            "examples": [
              "some string value"
            ]
          }
        },
        "description": "Example payload model demonstrating markdown text styling:\n**bold**, *cursive* and <u>underlined</u>\n",
        "examples": [
          {
            "someEnum": "FOO2",
            "someLong": 5,
            "someString": "some string value"
          }
        ],
        "required": [
          "someEnum",
          "someString"
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Example payload model demonstrating markdown text styling:\n**bold**, *cursive* and <u>underlined</u>\n",
          "properties": {
            "someEnum": {
              "description": "Some enum field",
              "enum": [
                "FOO1",
                "FOO2",
                "FOO3"
              ],
              "type": "string"
            },
            "someLong": {
              "description": "Some long field",
              "format": "int64",
              "minimum": 0,
              "type": "integer"
            },
            "someString": {
              "description": "###  Some string field with Markdown\n\n- **bold**\n- *cursive*\n- images: <img src=\"./assets/springwolf-logo.png\" alt=\"Springwolf\" height=\"50\"/>\n- and code blocks (json, http, java)\n  ```json\n  {\n    \"key1\":\"value1\",\n    \"key2\":\"value2\"\n  }\n  ```\n",
              "type": "string"
            }
          },
          "required": [
            "someEnum",
            "someString"
          ],
          "title": "ExamplePayloadDto",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto": {
        "title": "NestedPayloadDto",
        "type": "object",
        "properties": {
          "examplePayloads": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
            }
          },
          "someStrings": {
            "type": "array",
            "items": {
              "type": "string",
              "description": "Some string field",
              "examples": [
                "some string value"
              ]
            },
            "uniqueItems": true
          }
        },
        "description": "Payload model with nested complex types",
        "examples": [
          {
            "examplePayloads": [
              {
                "someEnum": "FOO2",
                "someLong": 5,
                "someString": "some string value"
              }
            ],
            "someStrings": [
              "some string value"
            ]
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Payload model with nested complex types",
          "properties": {
            "examplePayloads": {
              "items": {
                "description": "Example payload model demonstrating markdown text styling:\n**bold**, *cursive* and <u>underlined</u>\n",
                "properties": {
                  "someEnum": {
                    "description": "Some enum field",
                    "enum": [
                      "FOO1",
                      "FOO2",
                      "FOO3"
                    ],
                    "type": "string"
                  },
                  "someLong": {
                    "description": "Some long field",
                    "format": "int64",
                    "minimum": 0,
                    "type": "integer"
                  },
                  "someString": {
                    "description": "###  Some string field with Markdown\n\n- **bold**\n- *cursive*\n- images: <img src=\"./assets/springwolf-logo.png\" alt=\"Springwolf\" height=\"50\"/>\n- and code blocks (json, http, java)\n  ```json\n  {\n    \"key1\":\"value1\",\n    \"key2\":\"value2\"\n  }\n  ```\n",
                    "type": "string"
                  }
                },
                "required": [
                  "someEnum",
                  "someString"
                ],
                "title": "ExamplePayloadDto",
                "type": "object"
              },
              "type": "array"
            },
            "someStrings": {
              "items": {
                "description": "Some string field",
                "type": "string"
              },
              "type": "array",
              "uniqueItems": true
            }
          },
          "title": "NestedPayloadDto",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto": {
        "title": "XmlPayloadDto",
        "type": "string",
        "properties": {
          "someAttribute": {
            "type": "string"
          },
          "someEnum": {
            "type": "string",
            "enum": [
              "FOO1",
              "FOO2",
              "FOO3"
            ]
          },
          "someLong": {
            "type": "integer",
            "format": "int64"
          },
          "someString": {
            "type": "string"
          }
        },
        "examples": [
          "<io.github.springwolf.examples.kafka.dtos.XmlPayloadDto someAttribute=\"string\"><someEnum>FOO1</someEnum><someLong>0</someLong><someString>string</someString></io.github.springwolf.examples.kafka.dtos.XmlPayloadDto>"
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "someAttribute": { },
            "someEnum": {
              "enum": [
                "FOO1",
                "FOO2",
                "FOO3"
              ],
              "type": "string"
            },
            "someLong": {
              "format": "int64",
              "type": "integer"
            },
            "someString": {
              "type": "string"
            }
          },
          "title": "XmlPayloadDto",
          "type": "string"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto": {
        "title": "YamlPayloadDto",
        "type": "string",
        "properties": {
          "someEnum": {
            "type": "string",
            "enum": [
              "FOO1",
              "FOO2",
              "FOO3"
            ]
          },
          "someLong": {
            "type": "integer",
            "format": "int64"
          },
          "someString": {
            "type": "string"
          }
        },
        "examples": [
          "someEnum: FOO1\nsomeLong: 0\nsomeString: string\n"
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "someEnum": {
              "enum": [
                "FOO1",
                "FOO2",
                "FOO3"
              ],
              "type": "string"
            },
            "someLong": {
              "format": "int64",
              "type": "integer"
            },
            "someString": {
              "type": "string"
            }
          },
          "title": "YamlPayloadDto",
          "type": "string"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
        "discriminator": "vehicleType",
        "title": "VehicleBase",
        "type": "object",
        "properties": {
          "powerSource": {
            "type": "string"
          },
          "topSpeed": {
            "type": "integer",
            "format": "int32"
          },
          "vehicleType": {
            "type": "string"
          }
        },
        "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
        "examples": [
          {
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
          "properties": {
            "powerSource": {
              "type": "string"
            },
            "topSpeed": {
              "format": "int32",
              "type": "integer"
            },
            "vehicleType": { }
          },
          "title": "VehicleBase",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleElectricPayloadDto": {
        "type": "object",
        "description": "Electric vehicle implementation of VehicleBase",
        "examples": [
          {
            "batteryCapacity": 0,
            "chargeTime": 0,
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "allOf": [
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          },
          {
            "type": "object",
            "properties": {
              "batteryCapacity": {
                "type": "integer",
                "format": "int32"
              },
              "chargeTime": {
                "type": "integer",
                "format": "int32"
              }
            }
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "allOf": [
            {
              "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
              "properties": {
                "powerSource": {
                  "type": "string"
                },
                "topSpeed": {
                  "format": "int32",
                  "type": "integer"
                },
                "vehicleType": { }
              },
              "title": "VehicleBase",
              "type": "object"
            },
            {
              "properties": {
                "batteryCapacity": { },
                "chargeTime": { }
              },
              "type": "object"
            }
          ],
          "description": "Electric vehicle implementation of VehicleBase",
          "type": "object"
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleGasolinePayloadDto": {
        "type": "object",
        "description": "Gasoline vehicle implementation of VehicleBase",
        "examples": [
          {
            "fuelCapacity": 0,
            "powerSource": "string",
            "topSpeed": 0,
            "vehicleType": "string"
          }
        ],
        "allOf": [
          {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          },
          {
            "type": "object",
            "properties": {
              "fuelCapacity": {
                "type": "integer",
                "format": "int32"
              }
            }
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "allOf": [
            {
              "description": "Demonstrates the use of discriminator for polymorphic deserialization (not publishable)",
              "properties": {
                "powerSource": {
                  "type": "string"
                },
                "topSpeed": {
                  "format": "int32",
                  "type": "integer"
                },
                "vehicleType": { }
              },
              "title": "VehicleBase",
              "type": "object"
            },
            {
              "properties": {
                "fuelCapacity": { }
              },
              "type": "object"
            }
          ],
          "description": "Gasoline vehicle implementation of VehicleBase",
          "type": "object"
        }
      },
      "java.lang.Integer": {
        "type": "integer",
        "format": "int32",
        "examples": [
          0
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "format": "int32",
          "type": "integer"
        }
      },
      "java.lang.String": {
        "type": "string",
        "examples": [
          "\"string\""
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "type": "string"
        }
      },
      "javax.money.MonetaryAmount": {
        "type": "object",
        "properties": {
          "amount": {
            "type": "number",
            "exclusiveMinimum": 0.01,
            "examples": [
              99.99
            ]
          },
          "currency": {
            "type": "string",
            "examples": [
              "USD"
            ]
          }
        },
        "examples": [
          {
            "amount": 99.99,
            "currency": "USD"
          }
        ],
        "x-json-schema": {
          "$schema": "https://json-schema.org/draft-04/schema#",
          "properties": {
            "amount": {
              "exclusiveMinimum": 0.01,
              "type": "number"
            },
            "currency": {
              "type": "string"
            }
          },
          "type": "object"
        }
      }
    },
    "messages": {
      "PayloadNotUsed": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-PayloadNotUsed"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "title": "PayloadNotUsed",
            "type": "object",
            "properties": { },
            "description": "No payload specified"
          }
        },
        "name": "PayloadNotUsed",
        "title": "PayloadNotUsed",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope": {
        "headers": {
          "$ref": "#/components/schemas/HeadersNotUsed"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope"
          }
        },
        "name": "StringPayload",
        "title": "StringEnvelope",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto": {
        "headers": {
          "$ref": "#/components/schemas/HeadersNotDocumented"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto",
        "title": "AnotherPayloadAvroDto",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-Message"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message",
        "title": "Message",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-AnotherPayloadDto"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto",
        "title": "AnotherPayloadDto",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-ExamplePayloadDto-220435997"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto",
        "title": "ExamplePayloadDto",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto": {
        "headers": {
          "$ref": "#/components/schemas/SpringDefaultHeaderAndCloudEvent"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dtos.NestedPayloadDto",
        "title": "NestedPayloadDto",
        "bindings": {
          "kafka": {
            "key": {
              "type": "string",
              "description": "Kafka Producer Message Key",
              "examples": [
                "example-key"
              ]
            },
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto": {
        "headers": {
          "$ref": "#/components/schemas/HeadersNotDocumented"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
          }
        },
        "contentType": "text/xml",
        "name": "io.github.springwolf.examples.kafka.dtos.XmlPayloadDto",
        "title": "XmlPayloadDto",
        "description": "Showcases a xml based message",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto": {
        "headers": {
          "$ref": "#/components/schemas/HeadersNotDocumented"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
          }
        },
        "contentType": "application/yaml",
        "name": "io.github.springwolf.examples.kafka.dtos.YamlPayloadDto",
        "title": "YamlPayloadDto",
        "description": "Showcases a yaml based message",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-VehicleBase"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
          }
        },
        "name": "io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase",
        "title": "VehicleBase",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "java.lang.Integer": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-integer"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "type": "integer",
            "format": "int32",
            "examples": [
              0
            ]
          }
        },
        "name": "java.lang.Integer",
        "title": "integer",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "java.lang.String": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-string"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "type": "string",
            "examples": [
              "\"string\""
            ]
          }
        },
        "name": "java.lang.String",
        "title": "string",
        "bindings": {
          "kafka": {
            "bindingVersion": "0.5.0"
          }
        }
      },
      "javax.money.MonetaryAmount": {
        "headers": {
          "$ref": "#/components/schemas/SpringKafkaDefaultHeaders-MonetaryAmount"
        },
        "payload": {
          "schemaFormat": "application/vnd.aai.asyncapi+json;version=3.0.0",
          "schema": {
            "$ref": "#/components/schemas/javax.money.MonetaryAmount"
          }
        },
        "name": "javax.money.MonetaryAmount",
        "title": "MonetaryAmount",
        "bindings": {
          "kafka": {
            "key": {
              "type": "string",
              "description": "Kafka Consumer Message Key",
              "examples": [
                "example-key"
              ]
            },
            "bindingVersion": "0.5.0"
          }
        }
      }
    }
  },
  "operations": {
    "another-topic_receive_receiveAnotherPayloadBatched": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/another-topic"
      },
      "bindings": {
        "kafka": {
          "groupId": {
            "type": "string",
            "enum": [
              "example-group-id"
            ]
          },
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/another-topic/messages/io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
        }
      ]
    },
    "avro-topic_receive_receiveExampleAvroPayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/avro-topic"
      },
      "title": "avro-topic_receive",
      "description": "Requires a running kafka-schema-registry. See docker-compose.yml to start it",
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/avro-topic/messages/io.github.springwolf.examples.kafka.dto.avro.AnotherPayloadAvroDto"
        }
      ]
    },
    "example-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/example-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/example-topic/messages/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
        }
      ]
    },
    "integer-topic_receive_receiveIntegerPayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/integer-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/integer-topic/messages/java.lang.Integer"
        }
      ]
    },
    "multi-payload-topic_receive_ExampleClassLevelKafkaListener": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/multi-payload-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/multi-payload-topic/messages/io.github.springwolf.examples.kafka.dtos.ExamplePayloadDto"
        },
        {
          "$ref": "#/channels/multi-payload-topic/messages/io.github.springwolf.examples.kafka.dtos.AnotherPayloadDto"
        },
        {
          "$ref": "#/channels/multi-payload-topic/messages/javax.money.MonetaryAmount"
        }
      ]
    },
    "multi-payload-topic_receive_receiveMonetaryAmount": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/multi-payload-topic"
      },
      "title": "multi-payload-topic_receive",
      "description": "Override description in the AsyncListener annotation with servers at kafka:29092",
      "bindings": {
        "kafka": {
          "groupId": {
            "type": "string",
            "enum": [
              "foo-groupId"
            ]
          },
          "clientId": {
            "type": "string",
            "enum": [
              "foo-clientId"
            ]
          },
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/multi-payload-topic/messages/javax.money.MonetaryAmount"
        }
      ]
    },
    "no-payload-used-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/no-payload-used-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/no-payload-used-topic/messages/PayloadNotUsed"
        }
      ]
    },
    "protobuf-topic_receive_receiveExampleProtobufPayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/protobuf-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/protobuf-topic/messages/io.github.springwolf.examples.kafka.dto.proto.ExamplePayloadProtobufDto$Message"
        }
      ]
    },
    "string-topic_receive_receiveStringPayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/string-topic"
      },
      "title": "string-topic_receive",
      "description": "Final classes (like String) can be documented using an envelope class and the @AsyncApiPayload annotation.",
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/string-topic/messages/io.github.springwolf.examples.kafka.consumers.StringConsumer$StringEnvelope"
        },
        {
          "$ref": "#/channels/string-topic/messages/java.lang.String"
        }
      ]
    },
    "topic-defined-via-asyncPublisher-annotation_send_sendMessage": {
      "action": "send",
      "channel": {
        "$ref": "#/channels/topic-defined-via-asyncPublisher-annotation"
      },
      "title": "topic-defined-via-asyncPublisher-annotation_send",
      "description": "Custom, optional description defined in the AsyncPublisher annotation",
      "bindings": {
        "kafka": {
          "clientId": {
            "type": "string",
            "enum": [
              "foo-clientId"
            ]
          },
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/topic-defined-via-asyncPublisher-annotation/messages/io.github.springwolf.examples.kafka.dtos.NestedPayloadDto"
        }
      ]
    },
    "vehicle-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/vehicle-topic"
      },
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/vehicle-topic/messages/io.github.springwolf.examples.kafka.dtos.discriminator.VehicleBase"
        }
      ]
    },
    "xml-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/xml-topic"
      },
      "title": "xml-topic_receive",
      "description": "Auto-generated description",
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/xml-topic/messages/io.github.springwolf.examples.kafka.dtos.XmlPayloadDto"
        }
      ]
    },
    "yaml-topic_receive_receiveExamplePayload": {
      "action": "receive",
      "channel": {
        "$ref": "#/channels/yaml-topic"
      },
      "title": "yaml-topic_receive",
      "description": "Auto-generated description",
      "bindings": {
        "kafka": {
          "bindingVersion": "0.5.0"
        }
      },
      "messages": [
        {
          "$ref": "#/channels/yaml-topic/messages/io.github.springwolf.examples.kafka.dtos.YamlPayloadDto"
        }
      ]
    }
  }
}