<!-- SPDX-License-Identifier: Apache-2.0 -->
<div class="row">
  <div class="width-12/12 width-12/12-s">
    <div class="table margin-vertical-1em">
      <div class="table-row">
        <span>Channel</span>
        <span>{{ operation().channelName }}</span>
      </div>
      <div *ngIf="operation().description" class="table-row">
        <span>Operation description</span>
        <markdown [data]="operation().description"></markdown>
      </div>
      <div *ngIf="operation().message.description" class="table-row">
        <span>Message description</span>
        <markdown [data]="operation().message.description"></markdown>
      </div>
      <div *ngIf="operation().message.contentType" class="table-row">
        <span>Content-Type</span>
        <span class="text-console">{{ operation().message.contentType }}</span>
      </div>
      <div *ngIf="operation().reply" class="table-row">
        <span>Reply to</span>
        <span
          ><mat-chip-set>
            <a href="{{ operation().reply!.channelAnchorUrl }}">
              <mat-chip>
                <mat-icon matChipAvatar fontIcon="swap_vert" />
                {{ operation().reply!.channelName }}
              </mat-chip>
            </a>
          </mat-chip-set>
          with
          <mat-chip-set>
            <a href="{{ operation().reply!.messageAnchorUrl }}">
              <mat-chip>
                <mat-icon matChipAvatar fontIcon="schema" />
                {{ operation().reply!.messageName }}
              </mat-chip>
            </a>
          </mat-chip-set></span
        >
      </div>
      <div class="table-row">
        <span>Servers</span>
        <span>
          <mat-chip-set>
            @for (server of operation().servers; track server) {
            <a href="{{ server.anchorUrl }}">
              <mat-chip>
                <mat-icon matChipAvatar fontIcon="dns" />
                {{ server.name }}
              </mat-chip>
            </a>
            }
          </mat-chip-set>
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="isShowBindings">
  <div class="width-6/12 width-12/12-s">
    <h6>Operation Binding</h6>
  </div>
  <div class="width-6/12 width-12/12-s">
    <div *ngIf="operation().protocol">
      <app-prism-editor
        *ngIf="operationBindingExampleString"
        [(code)]="operationBindingExampleString"
        language="json"
        readonly="true"
      />
    </div>
  </div>
</div>

<div class="row" *ngIf="isShowBindings">
  <div class="width-6/12 width-12/12-s">
    <h6>Message Binding</h6>
  </div>
  <div class="width-6/12 width-12/12-s">
    <div>
      <app-prism-editor
        *ngIf="messageBindingExampleString"
        [(code)]="messageBindingExampleString"
        language="json"
        readonly="true"
      />
      <span *ngIf="!messageBindingExampleString"><i>none</i></span>
    </div>
  </div>
</div>

<div class="row" *ngIf="isShowHeaders">
  <div class="width-6/12 width-12/12-s">
    <h6>Headers</h6>
    <mat-chip-set>
      <a [href]="operation().message.headers.anchorUrl">
        <mat-chip>
          <mat-icon matChipAvatar fontIcon="schema" />
          {{ operation().message.headers.title }}
        </mat-chip></a
      >
    </mat-chip-set>

    <app-schema-new *ngIf="headers" [schema]="headers"></app-schema-new>
  </div>
  <div class="width-6/12 width-12/12-s">
    <app-prism-editor [(code)]="headersExample.value" language="json" />
  </div>
</div>

<div class="row">
  <div class="width-6/12 width-12/12-s">
    <h6>Payload</h6>
    <mat-chip-set>
      <a [href]="operation().message.payload.anchorUrl">
        <mat-chip>
          <mat-icon matChipAvatar fontIcon="schema" />
          {{ operation().message.payload.title }}
        </mat-chip></a
      >
    </mat-chip-set>

    <app-schema-new
      *ngIf="defaultSchema"
      [schema]="defaultSchema"
    ></app-schema-new>
  </div>

  <div class="width-6/12 width-12/12-s">
    <app-prism-editor
      [(code)]="defaultExample.value"
      [language]="exampleContentType()"
    />

    <div class="flex space-between">
      <div class="flex gap-8">
        <button mat-raised-button [cdkCopyToClipboard]="defaultExample.value">
          <mat-icon fontIcon="content_copy" />
          Copy
        </button>
        <button mat-raised-button (click)="reset()">
          <mat-icon fontIcon="restart_alt" />
          Reset
        </button>
      </div>
      <button mat-raised-button [disabled]="!canPublish" (click)="publish()">
        <mat-icon fontIcon="send" />
        Publish
      </button>
    </div>
  </div>
</div>
