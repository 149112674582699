<!-- SPDX-License-Identifier: Apache-2.0 -->
<h1>Servers</h1>
<mat-card appearance="outlined" *ngFor="let server of servers | keyvalue">
  <mat-card-header>
    <mat-card-title>{{ server.key }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table>
      <tbody>
        <tr>
          <td>Host:</td>
          <td>
            {{ server.value.host }}
          </td>
        </tr>
        <tr>
          <td>Protocol:</td>
          <td>
            {{ server.value.protocol }}
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
