<!-- SPDX-License-Identifier: Apache-2.0 -->
<div>
  <span
    class="range"
    *ngIf="schema().minimum != undefined && schema().maximum == undefined"
  >
    {{ schema().exclusiveMinimum ? ">" : ">=" }} {{ schema().minimum }}
  </span>
  <span
    class="range"
    *ngIf="schema().minimum == undefined && schema().maximum != undefined"
  >
    {{ schema().exclusiveMaximum ? "<" : "<=" }} {{ schema().maximum }}
  </span>
  <span
    class="range"
    *ngIf="schema().minimum != undefined && schema().maximum != undefined"
    >{{ schema().exclusiveMinimum ? "(" : "[" }} {{ schema().minimum }} ..
    {{ schema().maximum }} {{ schema().exclusiveMaximum ? ")" : "]" }}</span
  >
</div>
