<!-- SPDX-License-Identifier: Apache-2.0 -->
<h1>Channels</h1>

<mat-accordion>
  <mat-expansion-panel
    *ngFor="let channel of channels"
    [id]="channel.anchorIdentifier"
    [attr.data-testid]="channel.anchorIdentifier"
    [expanded]="selectedChannel == channel.anchorIdentifier"
    (opened)="this.setChannelSelection(channel)"
  >
    <mat-expansion-panel-header>
      <mat-panel-title class="flex-column gap-16 flex-start items-center">
        <div class="badge protocol-badge" *ngIf="channel.operation.protocol">
          {{ channel.operation.protocol }}
        </div>
        <div
          class="badge"
          [ngClass]="{
            'send-badge': channel.operation.operationType === 'send',
            'receive-badge': channel.operation.operationType === 'receive'
          }"
        >
          {{ channel.operation.operationType }}
        </div>
        <h3>{{ channel.name }}</h3>
        <span class="payload-name">{{ channel.operation.message.title }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-channel-main
      [channelName]="channel.name"
      [operation]="channel.operation"
    >
    </app-channel-main>
  </mat-expansion-panel>
</mat-accordion>
