<!-- SPDX-License-Identifier: Apache-2.0 -->
<div>
  <app-json [data]="schema().example?.rawValue"></app-json>
</div>
<p>Properties</p>
<!--  Primitive types  -->
<ng-container
  *ngIf="schema().type !== 'object'"
  [ngTemplateOutlet]="valueContent"
  [ngTemplateOutletContext]="{ value: schema() }"
>
</ng-container>
<table class="schema">
  <tbody>
    <!--  Objects  -->
    <tr *ngFor="let property of schema().properties || {} | keyvalue">
      <td class="key">
        {{ property.key }}
        <span class="required" *ngIf="schema().required?.includes(property.key)"
          >*</span
        >
      </td>
      <td>
        <ng-container *ngIf="property.value.items">
          <span
            class="type"
            *ngIf="
              property.value.items.type && !property.value.items.refAnchorUrl
            "
            >{{ property.value.items.type }}[]</span
          >
          <span class="type" *ngIf="property.value.items.refAnchorUrl">
            <a [href]="property.value.items.refAnchorUrl"
              >{{ property.value.items.refName }}[]</a
            >
          </span>
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="valueContent"
          [ngTemplateOutletContext]="{ value: property.value }"
        >
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #valueContent let-value="value">
  <div class="type-content">
    <span class="type" *ngIf="value.type != 'array'">{{ value.type }}</span>

    <app-schema-range [schema]="value"> </app-schema-range>
  </div>

  <span class="type" *ngIf="value.refTitle">
    <a [href]="value.anchorUrl">{{ value.refTitle }}</a>
  </span>
  <span class="format" *ngIf="value.format">({{ value.format }})</span>
  <div class="description">
    <markdown [data]="value.description"></markdown>
  </div>
  <span class="example" *ngIf="value.example"
    >example: {{ value.example.value }}</span
  >
  <mat-select *ngIf="value.enum" placeholder="Enum">
    <mat-option *ngFor="let enumValue of value.enum" [value]="enumValue"
      >{{ enumValue }}
    </mat-option>
  </mat-select>
</ng-template>
