<!-- SPDX-License-Identifier: Apache-2.0 -->
<section>
  <mat-divider></mat-divider>
  <div *ngIf="operation().description || operation().message.description">
    <div class="table">
      <div *ngIf="operation().description" class="table-row">
        <span>Operation description</span>
        <markdown [data]="operation().description"></markdown>
      </div>
      <div *ngIf="operation().message.description" class="table-row">
        <span>Message description</span>
        <markdown [data]="operation().message.description"></markdown>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Example">
      <div class="flex-column">
        <div
          [hidden]="
            operation().protocol == undefined ||
            isEmptyObject(
              operation().message.bindings.get(operation().protocol)
            )
          "
          class="flex-column"
        >
          <h4>Message Binding</h4>
          <textarea
            spellcheck="false"
            #bindingTextArea
            [rows]="messageBindingExampleTextAreaLineCount"
            [value]="
              createMessageBindingExample(
                operation().message.bindings.get(operation().protocol)
              )?.value
            "
            (keyup)="
              recalculateLineCount(
                'messageBindingExample',
                bindingTextArea.value
              )
            "
          ></textarea>
        </div>
        <div class="flex-column">
          <h4>Header</h4>
          <textarea
            spellcheck="false"
            #headersTextArea
            [rows]="headersTextAreaLineCount"
            [value]="headersExample.value || ''"
            (keyup)="recalculateLineCount('headers', headersTextArea.value)"
          ></textarea>
        </div>
        <div class="flex-column">
          <h4>Message</h4>
          <textarea
            spellcheck="false"
            #messageTextArea
            [rows]="exampleTextAreaLineCount"
            [value]="defaultExample.value || ''"
            (keyup)="recalculateLineCount('example', messageTextArea.value)"
          ></textarea>
        </div>
        <div class="button-container flex gap-8">
          <button
            mat-raised-button
            color="primary"
            [disabled]="!canPublish"
            (click)="
              publish(
                messageTextArea.value,
                defaultExampleType,
                headersTextArea?.value,
                bindingTextArea?.value
              )
            "
          >
            Publish
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="
              messageTextArea.value = defaultExample.value;
              headersTextArea.value = headersExample.value || '';
              bindingTextArea.value =
                createMessageBindingExample(
                  operation().message.bindings.get(operation().protocol)
                )?.value || '';
              recalculateLineCount(
                'messageBindingExample',
                bindingTextArea.value
              );
              recalculateLineCount('headers', headersTextArea.value);
              recalculateLineCount('example', messageTextArea.value)
            "
          >
            Default
          </button>
          <button
            mat-raised-button
            color="primary"
            [cdkCopyToClipboard]="messageTextArea.value"
          >
            Copy
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Schema">
      <h4>
        Schema:
        <a [href]="operation().message.payload.anchorUrl">{{
          operation().message.payload.name
        }}</a>
      </h4>
      <app-schema *ngIf="schema" [schema]="schema"></app-schema>
    </mat-tab>
    <mat-tab label="Headers">
      <h4>
        Schema:
        <a [href]="operation().message.headers.anchorUrl">{{
          operation().message.headers.name
        }}</a>
      </h4>
      <app-schema *ngIf="headers" [schema]="headers"></app-schema>
    </mat-tab>
    <mat-tab label="Operation Bindings">
      <div *ngIf="operation().protocol && operation().message.bindings">
        <app-json
          *ngIf="operation().protocol && operation().bindings"
          [data]="operation().bindings[operation().protocol]"
        ></app-json>
      </div>
    </mat-tab>
    <mat-tab label="Message Bindings">
      <div *ngIf="operation().protocol && operation().message.rawBindings">
        <app-json
          *ngIf="operation().protocol && operation().message"
          [data]="operation().message.rawBindings[operation().protocol]"
        ></app-json>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
